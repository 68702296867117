.css_time {
  position: absolute;
  bottom: 0px;
  right: 24px;
}
.news-page .news-bar {
  height: auto;
  position: relative;
  margin-bottom: 2%;
}
.news-page .news-bar .bannerWrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.news-page .news-bar .bannerWrapper img {
  width: 100%;
}
.news-page .fade-enter-active,
.news-page .fade-leave-active {
  transition: opacity 0.5s;
}
.news-page .fade-enter,
.news-page .fade-leave-to {
  opacity: 0;
}
.news-page .pagenav {
  margin-top: 2.71%;
  margin-bottom: 4.67%;
  text-align: center;
}
.news-page .pagenav .pagination li .active {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
.news-page .pagenav .pagination li a {
  margin-right: 0.83rem;
}
.news-page .pagenav .pagination li a:hover {
  border-color: #2b8dfa;
  background-color: #ffffff;
}
.news-page .pagenav .pagination li a:focus {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/ .newsListNav {
    height: 6rem;
  }
  .news-page .news-bar /deep/ .newsListNav .ListNav {
    margin-right: 15rem;
  }
}
